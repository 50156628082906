
.actions {
  margin: 30px auto;
  padding: 0 72px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}
.items-wrapper {
  margin: 0 auto;
  padding: 0 72px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}
.qs-item {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;

  &__name {
    font-size: 1.2rem;
    font-weight: 600;
  }
  &__quantity {
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button
    {
      -webkit-appearance: unset;
      margin: unset;
    }

    input[type=number]
    {
      -moz-appearance: unset;
    }

    button {
      font-weight: 500;
      font-size: 0.9rem;
      background-color: #C7161C;
      color: #ffffff;
      padding: 2px 10px;
    }
  }
  &__quantity-input {
    margin-right: 30px;
    border: 1px solid #f2f2f2;
    padding: 10px;
  }
}
