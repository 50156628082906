@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap&subset=latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap&subset=latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap&subset=latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap&subset=latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.comparison-modal-visible {
  overflow: hidden;
}

input {
  border: 0;
}

:focus {
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

.mc-flex {
  display: flex;
}

.mc-mw-1200 {
  max-width: 1200px;
}

.mc-w-100 {
  width: 100%;
}

.mc-my-auto {
  margin: 0 auto;
}

.page {
  margin-bottom: 60px;
}
.page__header {
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  max-width: 825px;
}
@media (max-width: 576px) {
  .page__header {
    padding: 0 30px;
  }
}
.page__header--text-center {
  text-align: center;
}
.page__header--text-center .page__title {
  font-size: 136px;
  font-size: 8.5rem;
  line-height: 0.4264705882em;
  color: #eeeeee;
}
.page__title {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.2083333333em;
  font-style: normal;
  font-weight: bold;
}
@media (max-width: 576px) {
  .page__title {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.3846153846em;
  }
}
.page__subtitle {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.3125em;
  font-style: normal;
  font-weight: bold;
}
.page__body {
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  max-width: 825px;
}
.page__body h1 {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.2083333333em;
  font-style: normal;
  font-weight: bold;
}
@media (max-width: 576px) {
  .page__body h1 {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 1.3571428571em;
  }
}
.page__body h2 {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 1.25em;
  font-style: normal;
  font-weight: bold;
}
@media (max-width: 576px) {
  .page__body h2 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.2em;
  }
}
.page__body h3 {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.3125em;
  font-style: normal;
  font-weight: bold;
}
.page__body h4 {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.4166666667em;
  font-style: normal;
  font-weight: bold;
}
.page__body h5 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5625em;
  font-style: normal;
  font-weight: bold;
}
.page__body h6 {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.8333333333em;
  font-style: normal;
  font-weight: bold;
}
.page__body p {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: normal;
  color: #999999;
}
@media (max-width: 576px) {
  .page__body ul {
    padding: 0 0 0 20px;
  }
}
.page__body ul li {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: normal;
  color: #999999;
}
.page--order-details .page__header {
  max-width: 1200px;
}
.page--order-details .page__body {
  max-width: 1200px;
}
.page--order-details .page__footer {
  max-width: 1200px;
}
.homepage {
  min-height: 2950px;
}

.products__header {
  margin: 0 auto;
  padding: 15px 30px;
  width: 100%;
  max-width: 1200px;
}
@media (max-width: 575px) {
  .products__header {
    padding: 15px;
  }
}
.products__body {
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 575px) {
  .products__body {
    padding: 30px 15px 15px 15px;
  }
}
.products__aside {
  margin-right: 15px;
  width: 20%;
}
@media (max-width: 575px) {
  .products__aside {
    width: 100%;
    display: none;
  }
}
.products__aside-show {
  display: none;
}
@media (max-width: 575px) {
  .products__aside-show {
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 88;
    padding: 15px;
  }
}
.products__aside-top {
  display: none;
}
@media (max-width: 575px) {
  .products__aside-top {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
  }
}
.products__main {
  width: 78%;
}
@media (max-width: 575px) {
  .products__main {
    width: 100%;
  }
}
.products__title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2222222222em;
  font-style: normal;
  font-weight: bold;
}
@media (max-width: 575px) {
  .products__title {
    width: 100%;
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 1.4545454545em;
    text-align: center;
  }
}
.products__top {
  padding-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 575px) {
  .products__top {
    display: block;
  }
}
.products__top-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 575px) {
  .products__top-actions {
    margin-top: 30px;
  }
}
.products__filter-toggle {
  display: none;
}
@media (max-width: 575px) {
  .products__filter-toggle {
    display: block;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08);
    border-radius: 100px;
  }
}
.products__filter-toggle-inner {
  padding: 0 30px;
}
.products__filter-toggle-title {
  margin-right: 10px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #000000;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 3.2222222222em;
}
.products__filter-toggle-icon {
  position: relative;
  top: 4px;
}
.products__sort-toggle {
  position: relative;
}
@media (max-width: 575px) {
  .products__sort-toggle {
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.08);
    border-radius: 100px;
  }
}
.products__sort-toggle-inner {
  padding: 0 30px;
  cursor: pointer;
}
.products__sort-toggle-title {
  margin-right: 10px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #000000;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1em;
}
@media (max-width: 575px) {
  .products__sort-toggle-title {
    line-height: 3.2222222222em;
  }
}
.products__sort-toggle-icon {
  position: relative;
  top: 3px;
}
.products__sort-toggle-icon.v svg {
  transform: rotateX(180deg);
}
.products__sort-toggle-icon.n svg {
  transform: rotateX(360deg);
}
.products__sort-toggle-dropdown {
  padding: 15px;
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 80;
  width: 224px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.08);
}
.products__sort-toggle-dropdown ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.products__sort-toggle-dropdown ul li {
  padding: 3px 0;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em;
  cursor: pointer;
}
.products__items {
  padding-top: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.products__items .mc-product {
  margin-bottom: 30px;
}
.products__items svg.loader {
  width: 100px;
  height: 100px;
  margin: 20px;
  display: inline-block;
}
.products__bottom {
  margin-top: 30px;
  margin-bottom: 120px;
  text-align: center;
}

.loader {
  width: 100%;
  text-align: center;
}

.about-us {
  margin-bottom: 60px;
}
.about-us__header {
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  max-width: 825px;
}
@media (max-width: 576px) {
  .about-us__header {
    padding: 0 30px;
  }
}
.about-us__title {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.2083333333em;
  font-style: normal;
  font-weight: bold;
}
@media (max-width: 576px) {
  .about-us__title {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.3846153846em;
  }
}
.about-us__body {
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  max-width: 825px;
}
.about-us__body h2 {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
}
.about-us__body p {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: normal;
  color: #999999;
}
.news__header {
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  max-width: 1200px;
}
@media (max-width: 576px) {
  .news__header {
    padding: 0 30px;
  }
}
.news__title {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.2083333333em;
  font-style: normal;
  font-weight: bold;
}
@media (max-width: 576px) {
  .news__title {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.3846153846em;
  }
}
.news__body {
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.news__body article {
  margin-bottom: 45px;
}
.news__footer {
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact__body {
  margin: 0 auto;
  padding: 45px 30px 30px 30px;
  width: 100%;
  max-width: 1200px;
  display: flex;
}
@media (max-width: 575px) {
  .contact__body {
    padding: 0 30px 30px 30px;
    flex-direction: column-reverse;
  }
}
.contact__map-wrapper {
  height: 400px;
  background-color: gray;
}
.contact__main {
  margin-right: 15px;
  width: 75%;
}
@media (max-width: 575px) {
  .contact__main {
    margin-right: 0;
    width: 100%;
  }
}
.contact__aside {
  margin-left: 15px;
  width: 25%;
}
@media (max-width: 575px) {
  .contact__aside {
    margin-left: 0;
    width: 100%;
    transform: translateY(-40px);
  }
}
.contact__title {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.2083333333em;
  font-style: normal;
  font-weight: bold;
  color: #000000;
}
.contact__form {
  margin-top: 30px;
}
.contact__form-success-message {
  margin: 15px 0 30px;
}
.contact__form-success-message--inner {
  padding: 15px;
  background-color: rgba(69, 232, 144, 0.3803921569);
  border-radius: 5px;
}
.contact__form-success-message--inner span.c1 {
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: 600;
}
.contact-info {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.contact-info__title {
  margin-bottom: 15px;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.2380952381em;
  font-style: normal;
  font-weight: bold;
  color: #000000;
}
.contact-info__group:not(:last-child) {
  margin-bottom: 30px;
}
.contact-info__line {
  display: flex;
}
.contact-info__line-key {
  margin-right: 10px;
  font-weight: bold;
}
.contact-info__line-link {
  color: #000000;
  text-decoration: none;
}

.cart {
  margin-bottom: 60px;
}
.cart__header {
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  max-width: 1200px;
}
@media (max-width: 576px) {
  .cart__header {
    padding: 0 30px;
  }
}
.cart__header--flex {
  display: flex;
}
.cart__header--space-between {
  justify-content: space-between;
}
.cart__header--center {
  align-items: center;
}
.cart__body {
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .cart__body {
    padding: 0;
    flex-direction: column-reverse;
  }
}
.cart__title {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.2083333333em;
  font-style: normal;
  font-weight: bold;
}
@media (max-width: 576px) {
  .cart__title {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.3846153846em;
  }
}
.cart__main {
  padding-right: 15px;
  width: 75%;
}
@media (max-width: 575px) {
  .cart__main {
    width: 100%;
  }
}
.cart__aside {
  width: 25%;
}
@media (max-width: 575px) {
  .cart__aside {
    padding-right: unset;
    width: 100%;
  }
}
.cart .back-to-products {
  color: #DD181F;
  text-decoration: none;
}
.cart .back-to-products:hover {
  text-decoration: underline;
}
@media (max-width: 576px) {
  .cart__empty-msg {
    padding: 0 30px;
  }
}

.login__header {
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  max-width: 825px;
}
@media (max-width: 576px) {
  .login__header {
    padding: 0 30px;
  }
}
.login__title {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.2083333333em;
  font-style: normal;
  font-weight: bold;
}
@media (max-width: 576px) {
  .login__title {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.3846153846em;
  }
}
.login__body {
  margin: 0 auto 90px auto;
  padding: 0 30px;
  width: 100%;
  max-width: 825px;
}
@media (max-width: 576px) {
  .login__body {
    margin: 0 auto 30px auto;
    padding: 30px 30px;
  }
}

.reset-password__header {
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  max-width: 825px;
}
.reset-password__title {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.2083333333em;
  font-style: normal;
  font-weight: bold;
}
@media (max-width: 576px) {
  .reset-password__title {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.3846153846em;
  }
}
.reset-password__body {
  margin: 0 auto 90px auto;
  padding: 0 30px;
  width: 100%;
  max-width: 825px;
}
.reset-password__body a {
  color: #DD181F;
}
.register__header {
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  max-width: 825px;
}
@media (max-width: 576px) {
  .register__header {
    padding: 0 30px;
  }
}
.register__title {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.2083333333em;
  font-style: normal;
  font-weight: bold;
}
@media (max-width: 576px) {
  .register__title {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.3846153846em;
  }
}
.register__body {
  margin: 0 auto 90px auto;
  padding: 0 30px;
  width: 100%;
  max-width: 825px;
}
@media (max-width: 576px) {
  .register__body {
    margin: 0 auto 30px auto;
    padding: 30px 30px;
  }
}
.my-orders__header {
  margin: 0 auto;
  padding: 30px 30px 15px 30px;
  width: 100%;
  max-width: 1200px;
}
@media (max-width: 576px) {
  .my-orders__header {
    padding: 0 30px;
  }
}
.my-orders__body {
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .my-orders__body {
    overflow-x: scroll;
  }
}
.my-orders__products {
  display: flex;
}
.my-orders__footer {
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-orders__title {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.2083333333em;
  font-style: normal;
  font-weight: bold;
}
@media (max-width: 576px) {
  .my-orders__title {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 1.3846153846em;
  }
}
.my-orders__list {
  padding: 0;
  list-style: none;
}
.my-orders__list li:not(:last-child) {
  padding-bottom: 4px;
}
.my-orders__username-title {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1em;
  font-style: normal;
  font-weight: bold;
}
.my-orders__company-title {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1em;
  font-style: normal;
  font-weight: bold;
}
.my-orders table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.my-orders td, .my-orders th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.my-orders tr:nth-child(even) {
  background-color: #dddddd;
}
.my-orders__table-wrapper {
  width: 100%;
}
@media (max-width: 576px) {
  .my-orders__table-wrapper {
    width: unset;
  }
}
.my-orders .table {
  display: flex;
  flex-flow: column nowrap;
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.5;
  border: none;
  flex: 1 1 auto;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.08);
}
.my-orders .th {
  display: none;
  font-weight: 700;
  background-color: #f2f2f2;
}
.my-orders .th > .td {
  white-space: normal;
  justify-content: center;
}
.my-orders .tr {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}
.my-orders .tr--top {
  background-color: #000 !important;
  color: #fff !important;
}
.my-orders .tr:nth-of-type(even) {
  background-color: #f2f2f2;
}
.my-orders .tr:nth-of-type(odd) {
  background-color: #ffffff;
}
.my-orders .td {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.8em;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0px;
  white-space: nowrap;
  border-bottom: 0 solid #d0d0d0;
  align-items: center;
}
.my-orders .text-left {
  text-align: left;
  justify-content: start !important;
}
.my-orders .text-right {
  text-align: right;
  justify-content: end !important;
}
.my-orders .text-primary {
  color: red;
}
.my-orders .mc-pill {
  padding: 2px 8px;
  border-radius: 4px;
  color: #fff;
}
.my-orders .mc-pill--order {
  background-color: green;
}
.my-orders .mc-pill--invoice {
  background-color: #035a9b;
}
.my-orders .mc-icon {
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
}
.my-orders .mc-icon:not(:last-child) {
  margin-right: 8px;
}

.mc-header {
  padding: 25px 0;
}
@media (max-width: 576px) {
  .mc-header {
    padding: 15px 0;
  }
}
.mc-header__inner {
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mc-header__logo-wrapper {
  position: relative;
  width: 160px;
  z-index: 999999;
}
.mc-header__homepage-link {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: transparent;
}

.mc-mobile-ham {
  display: none;
}
@media (max-width: 992px) {
  .mc-mobile-ham {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 18px;
    width: 24px;
  }
}
.mc-mobile-ham__line {
  display: block;
  width: 24px;
  height: 3px;
  background-color: #000000;
  border-radius: 1px;
}
.main-menu-show .main-menu {
  display: flex;
  flex-direction: column;
  transform: translateX(0);
}

.mc-footer {
  padding: 60px 0;
  background: linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 100%);
}
.mc-footer__inner {
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;
}
.mc-footer__body {
  display: flex;
}
@media (min-width: 320px) and (max-width: 480px) {
  .mc-footer__body {
    flex-direction: column;
  }
}
.mc-footer__col {
  width: 25%;
}
@media (min-width: 320px) and (max-width: 480px) {
  .mc-footer__col {
    width: 100%;
    text-align: center;
  }
}
.mc-footer__social-icons {
  display: flex;
  list-style: none;
  margin-left: 35px;
  padding: 0;
}
@media (min-width: 320px) and (max-width: 480px) {
  .mc-footer__social-icons {
    margin-left: unset;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
}
.mc-footer__social-icon-item {
  margin: 0 5px;
  width: 22px;
  height: 22px;
}
.mc-footer__social-icon-item img {
  width: 100%;
}
.mc-footer__title {
  margin: 0;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.2380952381em;
  color: #000000;
  font-weight: 700;
}
.mc-footer__title-pt-30 {
  padding-top: 30px;
}
.mc-footer__list {
  padding: 0;
  list-style: none;
}
.mc-footer__list-text-bold {
  margin-right: 10px;
  color: #999999;
  font-weight: bold;
  font-style: normal;
}
.mc-footer__list-item {
  margin-bottom: 7px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
}
.mc-footer__list-item--no-padding {
  margin-bottom: 0;
}
.mc-footer__list-link {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
  color: #999999;
  text-decoration: none;
  transition: all 0.2s linear;
}
.mc-footer__list-link:hover {
  color: #000000;
}
.mc-footer__newsletter-wrapper {
  margin: 15px 0;
}
.mc-footer__footer {
  margin-top: 30px;
  color: #999999;
}
@media (max-width: 576px) {
  .mc-footer__footer {
    text-align: center;
  }
}
.mc-footer__author {
  margin-top: 24px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
  color: #999999;
}
.mc-footer__author a {
  text-decoration: none;
  color: #DD181F;
}
.mc-footer__author a:hover {
  text-decoration: underline;
}

@media (max-width: 992px) {
  .main-menu {
    padding: 24px 30px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999;
    transform: translateX(-100%);
    transition: transform 0.2s ease-in-out;
  }
}
.main-menu__close {
  display: none;
}
@media (max-width: 992px) {
  .main-menu__close {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 18px;
    width: 24px;
  }
}
.main-menu__menu-close-line {
  position: relative;
  display: block;
  width: 25px;
  height: 3px;
  background-color: #DD181F;
  border-radius: 1px;
}
.main-menu__close-line--top {
  top: 4px;
  transform: rotate(45deg);
}
.main-menu__close-line--bottom {
  bottom: 5px;
  transform: rotate(-45deg);
}
.main-menu__items {
  padding: 0;
  display: flex;
  list-style: none;
}
@media (max-width: 992px) {
  .main-menu__items {
    flex-direction: column;
  }
}
@media (max-width: 576px) {
  .main-menu__items {
    padding: 30px 0 0 0;
  }
}
.main-menu__item {
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .main-menu__item {
    padding: 15px 0;
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.2380952381em;
    color: #000000;
    text-transform: capitalize;
    text-align: center;
  }
  .main-menu__item:not(:last-child) {
    border-bottom: 1px solid #E6E6E6;
  }
}
.main-menu__item:not(:last-child) {
  margin-right: 40px;
}
@media (max-width: 992px) {
  .main-menu__item:not(:last-child) {
    margin-right: 0;
  }
}
.main-menu__link {
  color: #BFBFBF;
  text-decoration: none;
}
@media (max-width: 992px) {
  .main-menu__link {
    color: #000000;
  }
}
.main-menu__link:hover {
  color: #000000;
}
.main-menu__link-red {
  color: #C7161C;
  text-decoration: none;
}
@media (max-width: 992px) {
  .main-menu__link-red {
    color: #C7161C;
  }
}
.main-menu__link-red:hover {
  color: #000000;
}
.main-menu__link--active {
  color: #000000;
}
@media (max-width: 768px) {
  .main-menu__link--active {
    color: #DD181F;
  }
}
.main-menu__link--active::before {
  content: "";
  position: absolute;
  top: -41px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #DD181F;
}
@media (max-width: 992px) {
  .main-menu__link--active::before {
    display: none;
  }
}
.main-menu__mobile-cta {
  display: none;
}
@media (max-width: 576px) {
  .main-menu__mobile-cta {
    display: block;
  }
}

.user-menu {
  display: flex;
}
.user-menu__item {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
}
.user-menu__item:not(:last-child) {
  margin-right: 25px;
}
.user-menu__link {
  display: flex;
  align-items: center;
  color: #000000;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}
.user-menu__link:hover {
  color: #DD181F;
}
.user-menu__link:hover .user-menu__badge {
  color: #fff;
  background-color: #000;
}
.user-menu__link-icon {
  margin-left: 10px;
  position: relative;
  top: 2px;
}
.user-menu__badge {
  position: absolute;
  top: -9px;
  left: 9px;
  width: 18px;
  height: 18px;
  font-size: 10px;
  font-size: 0.625rem;
  line-height: 1.2em;
  color: #fff;
  background-color: #DD181F;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
}
@media (max-width: 575px) {
  .user-menu__item--login {
    display: none;
  }
}
@media (max-width: 575px) {
  .user-menu__item--cart .user-menu__link-text {
    display: none;
  }
}
.user-menu__comparison {
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mc-main-slider__inner {
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .mc-main-slider__inner {
    padding: 0 15px;
    height: unset;
  }
}
.mc-main-slider__slide {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .mc-main-slider__slide {
    padding-left: 0;
    flex-direction: column-reverse;
  }
}
.mc-main-slider__meta {
  width: 40%;
}
@media (max-width: 768px) {
  .mc-main-slider__meta {
    width: 100%;
    text-align: center;
  }
}
.mc-main-slider__brand {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  text-transform: uppercase;
  color: #BFBFBF;
}
.mc-main-slider__title {
  margin: 10px 0 10px 0;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.125em;
  color: #000000;
}
.mc-main-slider__description {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.5555555556em;
  color: #999999;
}
@media (max-width: 576px) {
  .mc-main-slider__description {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5em;
  }
}
.mc-main-slider__price-wrapper {
  margin: 15px 0 20px 0;
}
.mc-main-slider__price {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 31px;
  font-size: 32px;
  font-size: 2rem;
  line-height: 1em;
  color: #DD181F;
}
.mc-main-slider__currency {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
  color: #BFBFBF;
  text-transform: uppercase;
}
.mc-main-slider__image-wrapper {
  width: 50%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .mc-main-slider__image-wrapper {
    width: 100%;
    height: unset;
  }
}
@media (max-width: 575px) {
  .mc-main-slider__image {
    width: 100%;
  }
}
.mc-main-slider .swiper-slide {
  padding-bottom: 30px;
}
.mc-main-slider .swiper-pagination {
  position: relative;
  width: 100%;
}
@media (max-width: 768px) {
  .mc-main-slider .swiper-pagination {
    flex-direction: row;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .mc-main-slider .swiper-slide-active {
    filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08));
  }
}
.mc-main-slider .swiper-pagination-bullet {
  margin: 4px 4px 45px 4px !important;
  width: 18px;
  height: 18px;
  opacity: 0.1;
}
.mc-main-slider .swiper-pagination-bullet-active {
  opacity: 1;
  background: #DD181F;
}

.mc-quick-search {
  padding: 30px 0;
  background-color: #F2F2F2;
}
@media (max-width: 575px) {
  .mc-quick-search {
    display: none;
  }
}
.mc-quick-search__inner {
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mc-quick-search__input {
  padding: 20px 0;
  width: 100%;
  border-radius: 100px;
  text-indent: 45px;
}
@media (max-width: 575px) {
  .mc-quick-search__input {
    text-indent: 30px;
  }
}
.mc-quick-search__icon {
  position: absolute;
  top: 22px;
  right: 60px;
}

.mc-products-quick-search__inner {
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
}
@media (max-width: 575px) {
  .mc-products-quick-search__inner {
    padding: 0;
    border-radius: 10px;
  }
}
.mc-products-quick-search__input {
  padding: 20px 0;
  width: 100%;
  border-radius: 100px;
  text-indent: 15px;
}
.mc-products-quick-search__icon {
  position: absolute;
  top: 22px;
  left: 30px;
}
@media (max-width: 575px) {
  .mc-products-quick-search__icon {
    right: 30px;
    left: unset;
  }
}

.mc-product-banner {
  margin: 0 auto;
  padding: 55px 110px;
  width: 100%;
  max-width: 1200px;
  height: 554px;
  position: relative;
  display: flex;
  align-items: flex-end;
  border-radius: 10px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@media (max-width: 575px) {
  .mc-product-banner {
    padding: 30px;
    flex-direction: column;
    border-radius: 0;
  }
}
.mc-product-banner__title {
  font-size: 58px;
  font-size: 3.625rem;
  line-height: 1.1206896552em;
  color: #fff;
  font-style: normal;
  font-weight: 900;
}
@media (max-width: 575px) {
  .mc-product-banner__title {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1.2083333333em;
  }
}
.mc-product-banner .mc-product {
  position: absolute;
  top: 60px;
  right: 170px;
}
@media (max-width: 575px) {
  .mc-product-banner .mc-product {
    position: relative;
    top: unset;
    right: unset;
    width: 100%;
  }
}

.mc-product {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  transition: all 0.2s linear;
}
@media (max-width: 575px) {
  .mc-product {
    padding: 0;
  }
}
.mc-product__comparison-icon {
  position: relative;
  z-index: 60;
  cursor: pointer;
}
.mc-product__comparison-icon svg {
  transition: all 0.3s ease-in-out;
}
.mc-product__comparison-icon:hover svg {
  fill: #DD181F;
}
@media (max-width: 575px) {
  .mc-product__body {
    padding: 15px;
  }
}
.mc-product__info {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
}
.mc-product__info--discount {
  background-color: #DD181F;
  color: #fff;
}
.mc-product__image {
  width: 100%;
}
.mc-product__brand {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5em;
  color: #BFBFBF;
  text-transform: uppercase;
}
.mc-product__title {
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2222222222em;
  color: #000000;
}
@media (max-width: 575px) {
  .mc-product__title {
    min-height: 44px;
  }
}
.mc-product__short-description {
  margin-top: 0;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.2666666667em;
  color: #999999;
}
@media (max-width: 576px) {
  .mc-product__short-description {
    display: none;
  }
}
.mc-product__description {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  color: #999999;
}
.mc-product__price-eu .mc-product__price {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1em;
}
.mc-product__price {
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 31px;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.4761904762em;
  color: #DD181F;
}
.mc-product__currency {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
  color: #BFBFBF;
  text-transform: uppercase;
}
.mc-product__link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 50;
}
.mc-product--w25 {
  width: 23%;
}
.mc-product--w33 {
  width: 32%;
}
@media (max-width: 575px) {
  .mc-product--w33 {
    width: 48%;
  }
}
.mc-product--w100 {
  width: 100%;
}
.mc-product--featured {
  flex-direction: row;
  align-items: center;
}
.mc-product--featured:hover {
  box-shadow: unset !important;
}
.mc-product--featured:hover .mc-add-to-cart {
  background-color: #DD181F;
  color: #fff;
}
.mc-product--featured .mc-product__header {
  position: relative;
  width: 35%;
}
.mc-product--featured .mc-product__body {
  padding-left: 30px;
  width: 65%;
}
.mc-product--featured .mc-product__brand {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5em;
}
.mc-product--featured .mc-product__title {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.5em;
}
.mc-product--featured .mc-product__description {
  margin-top: 0;
  width: 80%;
}
.mc-product--featured .mc-product__price-wrapper {
  margin-bottom: 20px;
}
.mc-product:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.08);
}
.mc-product:hover .mc-add-to-cart {
  background-color: #DD181F;
  color: #fff;
}
.mc-product:hover .mc-add-to-cart:disabled {
  background-color: transparent;
  color: #E6E6E6;
}
.mc-product--loading .mc-post-card__title {
  display: block;
  width: 100%;
  height: 42px;
  background-color: #f3f3f3;
}
.mc-product--loading .price-login-alert__inner {
  display: block;
  width: 100%;
  height: 28px;
  background-color: #f3f3f3;
}
.mc-product--loading .mc-add-to-cart {
  display: block;
  width: 100%;
  height: 44px;
  background-color: #f3f3f3;
}
@keyframes hintloading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
@-webkit-keyframes hintloading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.mc-product--loading .mc-product__image,
.mc-product--loading .mc-product__title,
.mc-product--loading .price-login-alert__inner,
.mc-product--loading .mc-add-to-cart {
  animation: hintloading 2s ease-in-out 0s infinite reverse;
  -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
}

.ms-cart {
  margin-bottom: 60px;
}
@media (max-width: 576px) {
  .ms-cart {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.ms-cart__header {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}
@media (max-width: 576px) {
  .ms-cart__header {
    padding: 0 30px;
    justify-content: space-between;
  }
}
@media (max-width: 576px) {
  .ms-cart__body {
    padding: 0 30px;
  }
}
@media (max-width: 576px) {
  .ms-cart__table-wrapper {
    overflow-x: scroll;
  }
}
.ms-cart-table {
  width: 100%;
}
.ms-cart-table th {
  padding: 15px 20px 15px 0;
  text-align: left;
  color: #000;
  box-shadow: 0px -1px 0px #ECEEEF;
}
@media (max-width: 576px) {
  .ms-cart-table th {
    padding: 5px 0;
  }
}
.ms-cart-table__tr-title--right {
  text-align: right !important;
}

.ms-cart-item {
  box-shadow: 0px -1px 0px #ECEEEF;
}
@media (max-width: 575px) {
  .ms-cart-item {
    display: flex;
    align-items: center;
  }
}
.ms-cart-item__title {
  padding: 15px 20px 15px 0;
}
@media (max-width: 575px) {
  .ms-cart-item__title {
    padding: 0;
  }
}
.ms-cart-item__title a {
  color: #dd181f;
  text-decoration: none;
}
.ms-cart-item__quantity {
  padding: 15px 20px;
}
.ms-cart-item__single-price {
  padding: 15px 20px;
  text-align: right;
}
.ms-cart-item__total-price {
  padding: 15px 20px;
  text-align: right;
}

.mc-add-to-cart {
  display: block;
  position: relative;
  padding: 15px 40px;
  width: 100%;
  border: unset;
  background-color: transparent;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  color: #E6E6E6;
  cursor: pointer;
  z-index: 60;
  transition: all 0.2s linear;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 575px) {
  .mc-add-to-cart {
    margin: 0 auto;
    text-align: center;
    display: block;
    padding: 10px 40px;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.5714285714em;
  }
}
.mc-add-to-cart--red {
  background-color: #DD181F;
  color: #fff;
}
.mc-add-to-cart--border {
  border: 1px solid #DD181F;
  color: #DD181F;
}
.mc-add-to-cart--w216 {
  width: 216px;
}
.mc-add-to-cart:hover {
  background-color: #DD181F;
  color: #fff;
}
.mc-add-to-cart:disabled {
  background-color: transparent;
}

.mc-products-row {
  margin: 30px auto 45px auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;
}
@media (max-width: 575px) {
  .mc-products-row {
    margin: 30px auto 15px auto;
    padding: 0;
  }
}
.mc-products-row__header {
  padding: 20px 0;
  border-bottom: 1px solid #E6E6E6;
}
@media (max-width: 575px) {
  .mc-products-row__header {
    margin: 0 30px;
  }
}
.mc-products-row__body {
  margin-top: 20px;
}
.mc-products-row__body--flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.mc-products-row__title {
  display: flex;
  flex-direction: column;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.2380952381em;
}
.mc-products-row__title-400 {
  font-weight: 400;
}
.mc-products-row__title-700 {
  color: #DD181F;
  font-weight: 700;
  text-transform: uppercase;
}

.mc-news-row {
  margin: 30px auto 90px auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;
}
@media (max-width: 575px) {
  .mc-news-row {
    margin: 30px auto 15px auto;
    padding: 0;
  }
}
.mc-news-row__header {
  padding: 20px 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #E6E6E6;
}
@media (max-width: 575px) {
  .mc-news-row__header {
    margin: 0 30px;
  }
}
.mc-news-row__body {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 575px) {
  .mc-news-row__body {
    padding: 0 30px;
    flex-direction: column;
  }
}
.mc-news-row__body--slider {
  margin-top: 20px;
  display: unset;
  justify-content: unset;
}
@media (max-width: 575px) {
  .mc-news-row__body--slider {
    flex-direction: unset;
  }
}
.mc-news-row__body--flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.mc-news-row__title {
  display: flex;
  flex-direction: column;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.2380952381em;
}
.mc-news-row__title-400 {
  font-weight: 400;
}
.mc-news-row__title-700 {
  color: #DD181F;
  font-weight: 700;
  text-transform: uppercase;
}
.mc-news-row__origin {
  position: relative;
  color: #DD181F;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
}
.mc-news-row__origin::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background-color: #DD181F;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.2s cubic-bezier(0.79, 0.01, 0.22, 0.99) 0s, transform-origin 0s step-end;
}
.mc-news-row__origin:hover::after {
  transform: scaleX(1);
  transform-origin: left center;
}

.mc-categories {
  margin: 0 auto;
  padding: 0 30px 0 30px;
  width: 100%;
  max-width: 1200px;
  position: relative;
}
@media (max-width: 575px) {
  .mc-categories {
    display: none;
  }
}
.mc-categories__row {
  margin-bottom: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  gap: 30px;
}
.mc-categories--product-filter {
  padding: 0 0 1px 0;
}
.mc-categories__row--wrap {
  flex-wrap: wrap;
}
.mc-categories__row--gap-0 {
  gap: 0;
}

.mc-category-card {
  padding: 25px 20px 10px 20px;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.2s linear;
  margin-bottom: 15px;
}
.mc-category-card__title {
  width: 80%;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2222222222em;
  font-style: normal;
  font-weight: bold;
  color: #000000;
}
.mc-category-card__list {
  padding: 0;
  list-style: none;
}
.mc-category-card__item, .mc-category-card__link {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 2em;
  font-style: normal;
  font-weight: 600;
  color: #BFBFBF;
  text-decoration: none;
  transition: color 0.2s linear;
}
.mc-category-card__link:hover {
  color: #000000;
}
.mc-category-card__origin {
  position: relative;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 2em;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  color: #DD181F;
  text-decoration: none;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.mc-category-card__origin::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background-color: #DD181F;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.2s cubic-bezier(0.79, 0.01, 0.22, 0.99) 0s, transform-origin 0s step-end;
}
.mc-category-card__origin:hover::after {
  transform: scaleX(1);
  transform-origin: left center;
}
.mc-category-card--w25 {
  width: 25%;
}
.mc-category-card:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.08);
}
.mc-category-card:hover .mc-category-card__origin {
  opacity: 1;
}

.mc-post-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}
.mc-post-card__body {
  margin-bottom: 15px;
  padding: 10px 20px;
}
.mc-post-card__body p {
  margin-bottom: 0;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: normal;
  color: #999999;
}
.mc-post-card__image {
  width: 100%;
  height: 205px;
  border-radius: 5px;
}
.mc-post-card__title {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2222222222em;
  color: #000000;
}
.mc-post-card__content {
  margin-bottom: 0;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: normal;
  color: #999999;
}
.mc-post-card__meta-wrapper {
  margin-bottom: 10px;
}
.mc-post-card__date {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-style: normal;
  font-weight: bold;
  color: #DD181F;
}
.mc-post-card__link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 50;
}
.mc-post-card--w33 {
  width: 32%;
}
@media (min-width: 770px) and (max-width: 992px) {
  .mc-post-card--w33 {
    width: 50%;
  }
}
@media (min-width: 482px) and (max-width: 768px) {
  .mc-post-card--w33 {
    width: 50%;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .mc-post-card--w33 {
    width: 100%;
  }
}
.mc-post-card--loading .mc-post-card__image {
  height: 205px;
}
.mc-post-card--loading .mc-post-card__meta-wrapper {
  display: block;
  width: 80px;
  height: 20px;
  background-color: #f3f3f3;
}
.mc-post-card--loading .mc-post-card__title {
  display: block;
  width: 100%;
  height: 42px;
  background-color: #f3f3f3;
}
.mc-post-card--loading .mc-post-card__content {
  display: block;
  width: 100%;
  height: 72px;
  background-color: #f3f3f3;
}
.mc-post-card:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.08);
}
@keyframes hintloading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
@-webkit-keyframes hintloading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.mc-post-card.mc-post-card--loading .mc-post-card__image,
.mc-post-card.mc-post-card--loading .mc-post-card__meta-wrapper,
.mc-post-card.mc-post-card--loading .mc-post-card__title,
.mc-post-card.mc-post-card--loading .mc-post-card__content {
  animation: hintloading 2s ease-in-out 0s infinite reverse;
  -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
}

.mc-brands-row {
  margin: 30px auto 90px auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;
}
.mc-brands-row__header {
  padding: 20px 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #E6E6E6;
}
.mc-brands-row__body {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
}
.mc-brands-row__title {
  display: flex;
  flex-direction: column;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.2380952381em;
}
.mc-brands-row__title-400 {
  font-weight: 400;
}
.mc-brands-row__title-700 {
  color: #DD181F;
  font-weight: 700;
  text-transform: uppercase;
}
.mc-post__header {
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  max-width: 1200px;
}
.mc-post__featured-image {
  width: 100%;
}
.mc-post__body {
  margin: 0 auto;
  padding: 0 30px;
  width: 100%;
  max-width: 880px;
}
.mc-post__meta {
  padding: 20px 0;
}
@media (max-width: 768px) {
  .mc-post__meta {
    padding: 20px 0 10px 0;
  }
}
.mc-post__date {
  color: #DD181F;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
}
.mc-post__title {
  color: #000000;
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.2083333333em;
  font-style: normal;
  font-weight: bold;
}
@media (max-width: 768px) {
  .mc-post__title {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 1em;
  }
}
.mc-post__content h1 {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.2083333333em;
  font-style: normal;
  font-weight: bold;
}
.mc-post__content h2 {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 1.25em;
  font-style: normal;
  font-weight: bold;
}
.mc-post__content h3 {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.3125em;
  font-style: normal;
  font-weight: bold;
}
.mc-post__content h4 {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.4166666667em;
  font-style: normal;
  font-weight: bold;
}
.mc-post__content h5 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5625em;
  font-style: normal;
  font-weight: bold;
}
.mc-post__content h6 {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.8333333333em;
  font-style: normal;
  font-weight: bold;
}
.mc-post__content p {
  color: #999999;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625em;
  font-style: normal;
  font-weight: normal;
}
.mc-post__content a {
  color: #DD181F;
  font-weight: 600;
}
.mc-post__content ul li {
  color: #999999;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625em;
  font-style: normal;
  font-weight: normal;
}
.mc-post__content ol li {
  color: #999999;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625em;
  font-style: normal;
  font-weight: normal;
}
@media (max-width: 575px) {
  .mc-breadcrumbs {
    display: none;
  }
}
.mc-breadcrumbs__inner {
  margin: 0 auto;
  padding: 15px 30px 20px 30px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mc-breadcrumbs__items {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.mc-breadcrumbs__item {
  position: relative;
  color: #999999;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: normal;
}
.mc-breadcrumbs__item:not(:last-child) {
  margin-right: 20px;
}
.mc-breadcrumbs__item:not(:last-child)::before {
  content: "";
  width: 4px;
  height: 4px;
  position: absolute;
  top: 7px;
  right: -11px;
  background-color: #DD181F;
  border-radius: 50%;
}
.mc-breadcrumbs__item--current {
  color: #DD181F;
  font-style: normal;
  font-weight: bold;
}
.mc-breadcrumbs__link {
  color: #999999;
  text-decoration: none;
  transition: all 0.2s linear;
}
.mc-breadcrumbs__link:hover {
  color: #000000;
}

.mc-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  transition: all 0.2s linear;
  border-radius: 3px;
}
.mc-button--large {
  padding: 20px 60px;
}
.mc-button--border {
  border: 1px solid;
}
.mc-button--border-red {
  color: #DD181F;
  border-color: #DD181F;
}
.mc-button--border-red:hover {
  background-color: #DD181F;
  color: #fff;
}
.mc-button--quick-shopping {
  padding: 15px;
  width: 100%;
  background-color: #DD181F;
  color: #ffffff;
}
.mc-button--submit {
  padding: 20px 60px;
  color: #BFBFBF;
  background-color: #F2F2F2;
}
.mc-button--submit:hover {
  color: #000000;
  background-color: #BFBFBF;
}
@media (max-width: 575px) {
  .mc-button--submit {
    width: 100%;
  }
}
.mc-button--login {
  padding: 20px 60px;
  width: 100%;
  color: #fff;
  background-color: #DD181F;
}
.mc-button--login:hover {
  color: #fff;
  background-color: #C7161C;
}
.mc-button--reset-password {
  padding: 20px 60px;
  width: 100%;
  color: #fff;
  background-color: #DD181F;
}
.mc-button--reset-password:hover {
  color: #fff;
  background-color: #C7161C;
}
.mc-button--register {
  padding: 20px 60px;
  width: 100%;
  color: #fff;
  background-color: #DD181F;
}
.mc-button--register:hover {
  color: #fff;
  background-color: #C7161C;
}
.mc-button--newslette {
  padding: 15px 60px;
  color: #BFBFBF;
  background-color: #F2F2F2;
}
.mc-button--newslette:hover {
  color: #000000;
  background-color: #BFBFBF;
}
@media (max-width: 575px) {
  .mc-button--newslette {
    width: 100%;
  }
}
.mc-button--load-more {
  padding: 15px 60px;
  width: 100%;
  color: #BFBFBF;
  background-color: #F2F2F2;
}
.mc-button--load-more:hover {
  color: #000000;
  background-color: #BFBFBF;
}
@media (max-width: 575px) {
  .mc-button--load-more {
    width: 100%;
  }
}
.mc-button--cookie {
  padding: 15px 30px;
  color: #fff;
  background-color: #DD181F;
}
.mc-button--cookie:hover {
  color: #fff;
  background-color: #C7161C;
}
@media (max-width: 575px) {
  .mc-button--cookie {
    width: 100%;
  }
}
.mc-button--cart-download {
  display: block;
  width: 100%;
  padding: 15px;
  border: 1px solid;
  border-color: #DD181F;
  color: #DD181F;
  text-transform: capitalize;
  text-align: center;
  text-decoration: none;
}
.mc-button--cart-download:hover {
  background-color: #DD181F;
  color: #fff;
}
.mc-button--cart-download:not(:last-child) {
  margin-bottom: 10px;
}
.mc-button--cart-download:disabled {
  background-color: #f2f2f2;
  color: #bfbfbf;
  border: 0px solid;
}
.mc-button--remove {
  width: 100%;
  padding: 5px 15px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em;
  border: 1px solid;
  border-color: #DD181F;
  color: #DD181F;
  text-transform: capitalize;
}
.mc-button--remove:hover {
  background-color: #DD181F;
  color: #fff;
}
.mc-button--cart-save {
  padding: 7px 30px;
  text-decoration: none;
}
.mc-button--cart-clear {
  padding: 7px 30px;
  color: #fff;
  background-color: #DD181F;
  text-decoration: none;
}
.mc-button--block {
  display: block;
}
.mc-button--menu-login {
  display: block;
  margin-bottom: 5px;
  padding: 15px;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #DD181F;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
}
.mc-button--menu-register {
  display: block;
  padding: 15px;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #DD181F;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #DD181F;
  cursor: pointer;
}
.mc-button--error {
  display: block;
  margin-bottom: 5px;
  padding: 15px;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #DD181F;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
}
.mc-button--mt-20 {
  margin-top: 20px;
}

.quick-login {
  position: relative;
}
.quick-login__header {
  cursor: pointer;
}
.quick-login__header:hover .quick-login__text {
  color: #DD181F;
}
.quick-login__header:hover .quick-login__link-icon svg {
  fill: #DD181F;
}
.quick-login__text {
  color: #000000;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}
.quick-login__link-icon svg {
  fill: #000000;
  transition: fill 0.2s ease-in-out;
}
.quick-login__body {
  padding: 15px;
  position: absolute;
  top: 45px;
  right: 0;
  width: 330px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.08);
  z-index: 800;
}
.quick-login__body p {
  margin: 0;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.4285714286em;
  color: #4D4D4D;
  font-style: normal;
  font-weight: normal;
}
.quick-login__body p a {
  color: #DD181F;
}
.quick-login__form-group {
  margin-bottom: 15px;
}
.quick-login__form-label {
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  color: #000000;
  font-style: normal;
  font-weight: bold;
}
.quick-login__form-label-link {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.1666666667em;
  color: #DD181F;
  font-weight: 400;
  text-decoration: none;
}
.quick-login__form-input {
  padding: 13px 15px;
  width: 100%;
  border: 1px solid #F2F2F2;
  border-radius: 4px;
}
.quick-login__button {
  margin-top: 10px;
  display: block;
  padding: 15px;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #DD181F;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.quick-login__button:hover {
  background-color: #a81013;
}
.quick-login__button2 {
  margin-top: 5px;
  display: block;
  padding: 15px;
  width: 100%;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #DD181F;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #DD181F;
  border-color: #DD181F;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.quick-login__button2:hover {
  background-color: #DD181F;
  color: #fff;
}
.quick-login__register {
  margin-top: 15px;
  display: block;
  color: #DD181F;
  font-weight: 400;
}
.quick-login__link {
  color: #DD181F;
  font-weight: bold;
}

.products-filter {
  background-color: #fff;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.products-filter__header {
  padding: 10px 45px;
  display: flex;
  align-items: center;
}
.products-filter__body {
  padding: 0 45px;
}
.products-filter__title {
  margin-left: 45px;
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 2.0714285714em;
  color: #000000;
  font-style: normal;
  font-weight: bold;
}
.products-filter__arrow {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px;
  height: 53px;
  background-color: #F2F2F2;
  border-radius: 50%;
}
.products-filter__search {
  margin: 20px 0;
}
.products-filter__search-input {
  padding: 24px 45px;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.2380952381em;
  font-style: normal;
  font-weight: bold;
  color: #000000;
  background-color: #fff;
  width: 100%;
  border-radius: 100px;
  filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08));
}
.filter__dis {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}
.filter__header {
  padding: 0;
  border-bottom: 1px solid #EEEEEE;
}
.filter__body {
  padding: 15px 0 45px 0;
}
.filter__title {
  margin-bottom: 20px;
}
.filter__form-group {
  position: relative;
  padding: 5px 0;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
  color: #C9C9C9;
}
.filter__form-group input[type=checkbox]:checked {
  background-color: #DD181F;
  color: #DD181F;
}
.filter__form-group input[type=checkbox]:checked + label {
  color: #DD181F;
  text-decoration: line-through;
}
.filter__form-group-checkbox {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}
.filter .checkbox {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 10px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
  color: var(--color);
  cursor: pointer;
}
.filter .checkbox--disabled {
  color: var(--disabled);
}
.filter .checkbox:hover .checkbox__label {
  color: #DD181F;
}
.filter .checkbox__control {
  display: inline-grid;
  width: 18px;
  height: 18px;
  border: 1px solid #C9C9C9;
  border-radius: 3px;
}
.filter .checkbox__control svg {
  position: relative;
  top: 2px;
  left: 2px;
  transition: transform 0.1s ease-in 25ms;
  transform: scale(0);
  transform-origin: center center;
  width: 12px;
  height: 12px;
}
.filter .checkbox__input {
  display: grid;
  grid-template-areas: "checkbox";
  pointer-events: none;
}
.filter .checkbox__input > * {
  grid-area: checkbox;
}
.filter .checkbox__input input {
  opacity: 0;
  width: 18px;
  height: 18px;
}
.filter .checkbox__input input:checked + .checkbox__control {
  background-color: #DD181F;
  border: 1px solid #DD181F;
}
.filter .checkbox__input input:checked + .checkbox__control svg {
  transform: scale(1);
}
.filter .checkbox__input input:checked + .checkbox__label {
  color: #DD181F;
}
.filter .checkbox__input input:disabled + .checkbox__control {
  color: var(--disabled);
}
.filter .checkbox__label {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
  color: #C9C9C9;
}

.category-filter__header {
  padding: 0;
}
.category-filter__body {
  padding: 5px 0 30px 0;
  display: flex;
  flex-direction: column;
}
.category-filter__title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2222222222em;
  font-style: normal;
  font-weight: bold;
  color: #000000;
}
.category-filter__title a {
  color: #000000;
  text-decoration: none;
}
.category-filter__title a:hover {
  color: #DD181F;
}
.category-filter__link {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 2em;
  font-style: normal;
  font-weight: 600;
  color: #BFBFBF;
  text-decoration: none;
  transition: color 0.2s linear;
}
.category-filter__link:hover {
  color: #000000;
}
.category-filter__title--placeholder {
  height: 20px;
  width: 200px;
  background-color: #e7e7e7;
  border-radius: 3px;
}
.category-filter__link--placeholder-1 {
  margin: 4px 0;
  height: 12px;
  width: 160px;
  background-color: #ececec;
  border-radius: 2px;
}
.category-filter__link--placeholder-2 {
  margin: 4px 0;
  height: 12px;
  width: 140px;
  background-color: #ececec;
  border-radius: 2px;
}
.category-filter__link--placeholder-3 {
  margin: 4px 0;
  height: 12px;
  width: 130px;
  background-color: #ececec;
  border-radius: 2px;
}
.category-filter__link--placeholder-4 {
  margin: 4px 0;
  height: 12px;
  width: 115px;
  background-color: #ececec;
  border-radius: 2px;
}
.category-filter__link--placeholder-5 {
  margin: 4px 0;
  height: 12px;
  width: 100px;
  background-color: #ececec;
  border-radius: 2px;
}

.pagination__list {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
}
.pagination__item {
  width: 40px;
  height: 40px;
  color: #000000;
  background: #F2F2F2;
  border-radius: 50%;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
}
.pagination__item:not(:last-child) {
  margin-left: 14px;
}
.pagination__link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  text-decoration: none;
  border-radius: 50%;
}
.pagination__link:hover {
  color: #fff;
  background: #DD181F;
}
.pagination__link--active {
  color: #fff;
  background: #DD181F;
}
.s-product {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}
.s-product__header {
  padding: 30px 30px 0 30px;
  display: flex;
}
@media (max-width: 768px) {
  .s-product__header {
    flex-direction: column;
  }
}
@media (max-width: 576px) {
  .s-product__header {
    padding: 0 30px 0 30px;
  }
}
.s-product__body {
  padding: 0 30px;
}
.s-product__media {
  margin-right: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
@media (max-width: 575px) {
  .s-product__media {
    margin-right: 0;
    width: 100%;
  }
}
.s-product__info-main {
  margin-left: 30px;
  width: 50%;
}
@media (max-width: 575px) {
  .s-product__info-main {
    margin-left: 0;
    width: 100%;
  }
}
.s-product__brand {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  color: #BFBFBF;
}
.s-product__name {
  font-size: 32px;
  font-size: 2rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  color: #000000;
}
@media (max-width: 768px) {
  .s-product__name {
    font-size: 32px;
    font-size: 2rem;
    line-height: 1em;
  }
}
.s-product__paragraph {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: normal;
  color: #999999;
}
.s-product__attributes-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.s-product__attribute-item {
  margin: 0 10px 10px 0;
  padding: 5px 10px;
  background-color: #DD181F;
  color: #fff;
  border-radius: 3px;
  font-size: 0.8em;
}
.s-product__documents-wrapper {
  padding: 15px 0;
  display: flex;
  flex-direction: column;
}
.s-product__featured-image {
  width: 100%;
}
.s-product .state {
  position: absolute;
  left: -10000px;
}
.s-product .flex-tabs {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.s-product .flex-tabs .panel {
  padding: 15px 0;
  display: none;
  width: 100%;
  flex-basis: auto;
  border-top: 1px solid #eee;
}
.s-product .flex-tabs .panel h3 {
  margin: 15px 0 0 0;
  padding: 0;
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 1em;
}
.s-product .flex-tabs .panel p {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: normal;
  color: #6D6E71;
}
.s-product .tab {
  display: inline-block;
  padding: 15px 30px;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.2380952381em;
  font-weight: bold;
  color: #C9C9C9;
  cursor: pointer;
}
@media (max-width: 575px) {
  .s-product .tab {
    padding: 15px 0;
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 1.2380952381em;
    color: #000000;
  }
}
.s-product .tab:hover {
  color: #DD181F;
}
.s-product #tab-1:checked ~ .tabs #tab-1-label,
.s-product #tab-2:checked ~ .tabs #tab-2-label,
.s-product #tab-3:checked ~ .tabs #tab-3-label,
.s-product #tab-4:checked ~ .tabs #tab-4-label,
.s-product #tab-5:checked ~ .tabs #tab-5-label,
.s-product #tab-6:checked ~ .tabs #tab-6-label,
.s-product #tab-7:checked ~ .tabs #tab-7-label,
.s-product #tab-8:checked ~ .tabs #tab-8-label {
  color: #DD181F;
  cursor: default;
}
.s-product #tab-1:checked ~ .tabs #tab-1-label:hover,
.s-product #tab-2:checked ~ .tabs #tab-2-label:hover,
.s-product #tab-3:checked ~ .tabs #tab-3-label:hover,
.s-product #tab-4:checked ~ .tabs #tab-4-label:hover,
.s-product #tab-5:checked ~ .tabs #tab-5-label:hover,
.s-product #tab-6:checked ~ .tabs #tab-6-label:hover,
.s-product #tab-7:checked ~ .tabs #tab-7-label:hover,
.s-product #tab-8:checked ~ .tabs #tab-8-label:hover {
  color: #DD181F;
}
.s-product #tab-1:checked ~ .tabs #tab-1-panel,
.s-product #tab-2:checked ~ .tabs #tab-2-panel,
.s-product #tab-3:checked ~ .tabs #tab-3-panel,
.s-product #tab-4:checked ~ .tabs #tab-4-panel,
.s-product #tab-5:checked ~ .tabs #tab-5-panel,
.s-product #tab-6:checked ~ .tabs #tab-6-panel,
.s-product #tab-7:checked ~ .tabs #tab-7-panel,
.s-product #tab-8:checked ~ .tabs #tab-8panel {
  display: block;
}
@media (max-width: 600px) {
  .s-product .flex-tabs {
    flex-direction: column;
  }
  .s-product .flex-tabs .tab {
    border-bottom: 1px solid #ccc;
  }
  .s-product .flex-tabs .tab:last-of-type {
    border-bottom: none;
  }
  .s-product .flex-tabs #tab-1-label {
    order: 1;
  }
  .s-product .flex-tabs #tab-2-label {
    order: 3;
  }
  .s-product .flex-tabs #tab-3-panel {
    order: 2;
  }
  .s-product .flex-tabs #tab-4-panel {
    order: 4;
  }
  .s-product .flex-tabs #tab-5-panel {
    order: 5;
  }
  .s-product .flex-tabs #tab-6-panel {
    order: 6;
  }
  .s-product .flex-tabs #tab-7-panel {
    order: 7;
  }
  .s-product .flex-tabs #tab-8-panel {
    order: 8;
  }
  .s-product #tab-1:checked ~ .tabs #tab-1-label,
  .s-product #tab-1:checked ~ .tabs #tab-2-label,
  .s-product #tab-3:checked ~ .tabs #tab-3-label,
  .s-product #tab-4:checked ~ .tabs #tab-4-label,
  .s-product #tab-5:checked ~ .tabs #tab-5-label,
  .s-product #tab-6:checked ~ .tabs #tab-6-label,
  .s-product #tab-7:checked ~ .tabs #tab-7-label,
  .s-product #tab-8:checked ~ .tabs #tab-8-label {
    border-bottom: none;
  }
  .s-product #tab-1:checked ~ .tabs #tab-1-panel,
  .s-product #tab-2:checked ~ .tabs #tab-2-panel,
  .s-product #tab-3:checked ~ .tabs #tab-3-panel,
  .s-product #tab-4:checked ~ .tabs #tab-4-panel,
  .s-product #tab-5:checked ~ .tabs #tab-5-panel,
  .s-product #tab-6:checked ~ .tabs #tab-6-panel,
  .s-product #tab-7:checked ~ .tabs #tab-7-panel,
  .s-product #tab-8:checked ~ .tabs #tab-8-panel {
    border-bottom: 1px solid #ccc;
  }
}

.price-wrapper__head {
  padding: 15px 0 15px 0;
  border-bottom: 1px solid #eee;
}
.price-wrapper__body {
  padding: 15px 0;
  display: flex;
  flex-direction: column;
}
.price-wrapper__eu .price-wrapper__price-value--old {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1em;
}
.price-wrapper__hr-discount {
  position: relative;
  top: 10px;
}
.price-wrapper__eu-discount {
  position: relative;
  bottom: 5px;
}
.price-wrapper__eu-discount .price-wrapper__price-value {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1em;
}
.price-wrapper__special-price {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  color: #DD181F;
}
.price-wrapper__special-price-key {
  font-weight: bold;
}
.price-wrapper__special-price-value {
  font-weight: normal;
  text-decoration: line-through;
}
.price-wrapper__price {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}
.price-wrapper__price--quantity-0 {
  margin-left: 0;
}
.price-wrapper__price-value {
  font-size: 36px;
  font-size: 2.25rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  color: #DD181F;
}
.price-wrapper__price-value--old {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.6em;
  font-style: normal;
  font-weight: bold;
  color: #DD181F;
  text-decoration: line-through;
}
.price-wrapper__price-currency {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  color: #BFBFBF;
}
.price-wrapper__quantity {
  margin-top: 20px;
}
.price-wrapper__quantity .s1 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: normal;
  color: #000000;
}
.price-wrapper__quantity .s2 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: normal;
  color: #999999;
}

.ms-discount-label {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DD181F;
  border-radius: 50%;
}
.ms-discount-label__text {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.5em;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #fff;
}
.ms-discount-label--single-product {
  width: 90px;
  height: 90px;
}
.ms-discount-label--top {
  top: 0;
}
.ms-discount-label--right {
  right: 0;
}
.ms-discount-label--price {
  position: relative;
  width: 60px;
  height: 60px;
  left: 10px;
  bottom: 20px;
}
.ms-discount-label--price .ms-discount-label__text {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.7777777778em;
}

.ms-form-group {
  margin-bottom: 20px;
}
.ms-form-group__label {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  color: #000000;
}
.ms-form-group__input, .ms-form-group__textarea {
  padding: 12px 15px;
  width: 100%;
  border: 1px solid #F2F2F2;
  border-radius: 4px;
  -webkit-appearance: none;
}
.ms-form-group__input:focus, .ms-form-group__textarea:focus {
  outline: none;
}
.ms-form-group__input--invalid {
  border: 1px solid #DD181F;
}
.ms-form-group__link {
  color: #DD181F;
}
.ms-form-group__input--checkout {
  padding: unset;
  width: unset;
  border: unset;
  border-radius: unset;
}
.ms-form-group--checkout .ms-form-group__label {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-style: normal;
  font-weight: normal;
  color: #999999;
}
.ms-form-group--checkout .ms-form-group__label a {
  color: #D9534F;
}
.ms-form-group--radio {
  margin-bottom: 10px;
}
.ms-form-group--radio input {
  margin-right: 5px;
}
.ms-form-group input[type=radio]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.ms-form-group input[type=radio]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #DD181F;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.price-login {
  margin-top: 60px;
  padding: 35px 25px 25px 25px;
  position: relative;
  width: fit-content;
  background-color: #F2F2F2;
  border-radius: 5px;
}
@media (max-width: 768px) {
  .price-login {
    padding: 35px 15px 15px 15px;
    width: 100%;
  }
}
.price-login__icon {
  position: absolute;
  top: -20px;
  left: 25px;
}
.price-login__body {
  padding-top: 20px;
  display: flex;
}
@media (max-width: 992px) {
  .price-login__body {
    flex-direction: column;
  }
}
.price-login__info {
  color: #DD181F;
}
.price-login__button {
  padding: 10px 40px;
  display: block;
  border-radius: 3px;
}
@media (max-width: 992px) {
  .price-login__button {
    text-align: center;
  }
}
.price-login__button--full-red {
  margin-right: 25px;
  color: #fff;
  background-color: #DD181F;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
}
@media (max-width: 992px) {
  .price-login__button--full-red {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.price-login__button--full-red:hover {
  background-color: #A61217;
}
.price-login__button--border-red {
  color: #DD181F;
  border: 1px solid #DD181F;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
}
.price-login__button--border-red:hover {
  color: #fff;
  background-color: #DD181F;
}
.price-login--main-slider {
  margin-top: 30px;
}

.price-login-alert__inner {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .price-login-alert__inner {
    justify-content: center;
  }
}
.price-login-alert__icon {
  position: relative;
  bottom: 1px;
  margin-right: 5px;
  width: 14px;
  height: 14px;
}
.price-login-alert__text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.1666666667em;
  color: #DD181F;
}
.price-login-alert--main-slider {
  padding: 15px 0;
}
.price-login-alert--mc-product {
  padding: 0;
}

.ms-alert {
  padding: 20px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
@media (max-width: 576px) {
  .ms-alert {
    padding: 30px;
  }
}
.ms-alert__title {
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.2380952381em;
  font-style: normal;
  font-weight: bold;
  color: #D9534F;
}
.ms-alert__content {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  color: #6D6E71;
}
.ms-alert__text-block {
  margin: 30px 0 5px 0;
  display: block;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  color: #DD181F;
  font-weight: bold;
}
.ms-alert input {
  margin-bottom: 10px;
  padding: 12px 15px;
  width: 100%;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
}

.s-product-document {
  display: flex;
  align-items: center;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  text-decoration: none;
  width: fit-content;
}
.s-product-document:not(:last-child) {
  margin-bottom: 6px;
}
.s-product-document:hover .s-product-document__icon svg {
  fill: #DD181F;
}
.s-product-document:hover .s-product-document__text {
  color: #DD181F;
}
.s-product-document__icon {
  margin-right: 5px;
  display: flex;
}
.s-product-document__icon svg {
  fill: #4D4D4D;
}
.s-product-document__text {
  color: #4D4D4D;
}

.mc-flash-notice__inner {
  margin: 15px auto 15px auto;
  padding: 15px 20px;
  max-width: 1140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #DD181F;
  color: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.1428571429em;
  font-style: normal;
  font-weight: 500;
}
@media (max-width: 575px) {
  .mc-flash-notice__inner {
    border-radius: 0;
  }
}
.mc-flash-notice--absolute {
  position: absolute;
  left: 0;
  top: 68px;
  width: 100%;
  z-index: 9;
}
.mc-flash-notice--absolute .s-flash-notice__inner {
  max-width: 910px;
  padding: 15px;
}

.mc-delivery {
  margin-bottom: 60px;
}
.mc-delivery__header {
  padding: 0 0 30px 0;
}
.mc-delivery__title {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.2083333333em;
  font-style: normal;
  font-weight: bold;
}
@media (max-width: 576px) {
  .mc-delivery__title {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.4166666667em;
  }
}

.comparison-modal {
  position: absolute;
  top: 103px;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-top: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
  z-index: 9999;
  transform: translateX(100%);
  transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
}
.comparison-modal--show {
  transform: translateX(0);
}
.comparison-modal__inner {
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  max-width: 1200px;
}
.comparison-modal__body {
  display: flex;
  width: fit-content;
  overflow: auto;
  gap: 15px;
}
.comparison-modal__body .mc-product {
  width: 284px;
}

.quantity {
  position: relative;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.quantity input {
  width: 62px;
  height: 48px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  border: 1px solid #eee;
}

.quantity-cart input {
  width: 56px;
  height: 38px;
  line-height: 1.65;
  float: left;
  display: block;
  padding: 0;
  margin: 0;
  padding-left: 14px;
  border: 1px solid #eee;
}

.quantity input:focus {
  outline: 0;
}

.quantity-nav {
  float: left;
  position: relative;
  height: 48px;
}

.quantity-cart .quantity-nav {
  float: left;
  position: relative;
  height: 38px;
}

.quantity-button {
  position: relative;
  cursor: pointer;
  border-left: 1px solid #eee;
  width: 20px;
  text-align: center;
  color: #333;
  font-size: 13px;
  line-height: 1.7;
  font-weight: 500;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.quantity-button.quantity-up {
  position: absolute;
  height: 50%;
  top: 0;
  border-bottom: 1px solid #eee;
}

.quantity-button.quantity-down {
  position: absolute;
  bottom: -1px;
  height: 50%;
}

.add-to-cart {
  padding: 12px 46px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background-color: #DD181F;
  border: 0;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.add-to-cart:hover {
  background-color: #A61217;
}

.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 24px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #DD181F;
}
input:focus + .slider {
  box-shadow: 0 0 1px #DD181F;
}
input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

.products-slider .swiper-wrapper {
  padding: 0 0 45px 0;
}
@media (max-width: 575px) {
.products-slider .swiper-wrapper {
    padding: 0 0 60px 0;
}
}
@media (max-width: 575px) {
.products-slider .swiper-slide-active {
    margin-left: 30px;
    filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08));
}
}
.products-slider .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
.products-slider .swiper-pagination-bullet-active {
  opacity: 1;
  background: #DD181F;
}
.posts-slider {
  padding: 30px 0;
}
@media (max-width: 576px) {
.posts-slider {
    padding: 0 30px;
}
}
.posts-slider .swiper-wrapper {
  padding: 0 0 45px 0;
}
@media (max-width: 575px) {
.posts-slider .swiper-wrapper {
    padding: 0 0 60px 0;
}
}
@media (max-width: 575px) {
.posts-slider .swiper-slide-active {
    filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08));
}
}
.posts-slider .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
.posts-slider .swiper-pagination-bullet-active {
  opacity: 1;
  background: #DD181F;
}
.mc-products-quick-search {
  position: relative;
  text-align: center;
}
.mc-products-quick-search #autosuggest {
  width: 100%;
}
.mc-products-quick-search #autosuggest__input {
  outline: none;
  position: relative;
  width: 100%;
  border: 0;
  font-size: 21px;
  line-height: 36px;
  border-radius: 100px;
  color: #000000;
  background-color: #fff;
  font-weight: 600;
}
.mc-products-quick-search .autosuggest__label {
  visibility: hidden;
}
.mc-products-quick-search #autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.mc-products-quick-search .autosuggest__input--open {
  border-radius: 35px 35px 0 0 !important;
}
.mc-products-quick-search .autosuggest__results-container {
  position: relative;
  width: 100%;
}
.mc-products-quick-search .autosuggest__results {
  margin: 0 auto;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  text-transform: uppercase;
  color: #000;
  top: 0;
  filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08));
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}
.mc-products-quick-search .autosuggest__results ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}
.mc-products-quick-search .autosuggest__results-item-wrapper {
  display: flex;
  text-align: left;
  flex-direction: column;
}
.mc-products-quick-search .autosuggest__results-item-price {
  color: #DD181F;
  font-style: normal;
  font-weight: bold;
}
.mc-products-quick-search .autosuggest__results-item-price-currency {
  color: #BFBFBF;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
}
.mc-products-quick-search .autosuggest__results-item-description {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3571428571em;
}
.mc-products-quick-search .autosuggest__results-item {
  padding: 15px 45px;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2777777778em;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  color: #000000;
  cursor: pointer;
}
@media (max-width: 575px) {
.mc-products-quick-search .autosuggest__results-item {
    padding: 15px 30px;
}
}
.mc-products-quick-search .autosuggest__results-item:active, .mc-products-quick-search .autosuggest__results-item:hover, .mc-products-quick-search .autosuggest__results-item:focus {
  background-color: #F2F2F2;
}
.mc-products-quick-search .autosuggest__results-item-icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  visibility: hidden;
}
.mc-products-quick-search .autosuggest__results-item-icon-item-icon--villa {
  background-color: rgba(10, 187, 135, 0.1);
}
.mc-products-quick-search .autosuggest__results-item-icon-item-icon--location {
  background-color: rgba(10, 187, 135, 0.1);
}
.mc-products-quick-search .autosuggest__results-before {
  padding: 15px 45px;
  text-align: left;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.2380952381em;
  font-style: normal;
  font-weight: bold;
  color: #DD181F;
}
.mc-products-quick-search #autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.mc-quick-search {
  position: relative;
  text-align: center;
}
.mc-quick-search #autosuggest {
  width: 100%;
}
.mc-quick-search #autosuggest__input {
  outline: none;
  position: relative;
  width: 100%;
  border: 0;
  font-size: 21px;
  line-height: 36px;
  border-radius: 100px;
  color: #000000;
  background-color: #fff;
  font-weight: 600;
}
.mc-quick-search .autosuggest__label {
  visibility: hidden;
}
.mc-quick-search #autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.mc-quick-search .autosuggest__input--open {
  border-radius: 35px 35px 0 0 !important;
}
.mc-quick-search .autosuggest__results-container {
  position: relative;
  width: 100%;
}
.mc-quick-search .autosuggest__results {
  margin: 0 auto;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  text-transform: uppercase;
  color: #000;
  top: 0;
  filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08));
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}
.mc-quick-search .autosuggest__results ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}
.mc-quick-search .autosuggest__results-item-wrapper {
  display: flex;
  text-align: left;
  flex-direction: column;
}
.mc-quick-search .autosuggest__results-item-price {
  color: #DD181F;
  font-style: normal;
  font-weight: bold;
}
.mc-quick-search .autosuggest__results-item-price-currency {
  color: #BFBFBF;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
}
.mc-quick-search .autosuggest__results-item-description {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3571428571em;
}
.mc-quick-search .autosuggest__results-item {
  padding: 15px 45px;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2777777778em;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  color: #000000;
  cursor: pointer;
}
@media (max-width: 575px) {
.mc-quick-search .autosuggest__results-item {
    padding: 15px 30px !important;
}
}
.mc-quick-search .autosuggest__results-item:active, .mc-quick-search .autosuggest__results-item:hover, .mc-quick-search .autosuggest__results-item:focus {
  background-color: #F2F2F2;
}
.mc-quick-search .autosuggest__results-item-icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  visibility: hidden;
}
.mc-quick-search .autosuggest__results-item-icon-item-icon--villa {
  background-color: rgba(10, 187, 135, 0.1);
}
.mc-quick-search .autosuggest__results-item-icon-item-icon--location {
  background-color: rgba(10, 187, 135, 0.1);
}
.mc-quick-search .autosuggest__results-before {
  padding: 15px 45px;
  text-align: left;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.2380952381em;
  font-style: normal;
  font-weight: bold;
  color: #DD181F;
}
.mc-quick-search #autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}
.products-slider__inner[data-v-103d3256] {
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 1200px;
}
.products-slider__header[data-v-103d3256] {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.products-slider__title[data-v-103d3256] {
  font-family: "Playfair Display", serif;
  font-size: 46px;
  line-height: 54px;
  text-align: center;
  color: #668ca4;
}
.products-slider__subtitle[data-v-103d3256] {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #50504f;
}
.products-slider__body[data-v-103d3256] {
  padding: 30px 0;
  display: flex;
  gap: 20px;
}
.msg[data-v-bb764d18] {
  margin-top: 30px;
}
.mc-quick-search {
  position: relative;
  text-align: center;
}
.mc-quick-search #autosuggest {
  width: 100%;
}
.mc-quick-search #autosuggest__input {
  outline: none;
  position: relative;
  width: 100%;
  border: 0;
  font-size: 21px;
  line-height: 36px;
  border-radius: 100px;
  color: #000000;
  background-color: #fff;
  font-weight: 600;
}
.mc-quick-search .autosuggest__label {
  visibility: hidden;
}
.mc-quick-search #autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.mc-quick-search .autosuggest__input--open {
  border-radius: 35px 35px 0 0 !important;
}
.mc-quick-search .autosuggest__results-container {
  position: relative;
  width: 100%;
}
.mc-quick-search .autosuggest__results {
  margin: 0 auto;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  text-transform: uppercase;
  color: #000;
  top: 0;
  filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.08));
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
}
.mc-quick-search .autosuggest__results ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}
.mc-quick-search .autosuggest__results-item-wrapper {
  display: flex;
  text-align: left;
  flex-direction: column;
}
.mc-quick-search .autosuggest__results-item-price {
  color: #DD181F;
  font-style: normal;
  font-weight: bold;
}
.mc-quick-search .autosuggest__results-item-price-currency {
  color: #BFBFBF;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5em;
}
.mc-quick-search .autosuggest__results-item-description {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3571428571em;
}
.mc-quick-search .autosuggest__results-item {
  padding: 15px 45px;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2777777778em;
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  color: #000000;
  cursor: pointer;
}
@media (max-width: 575px) {
.mc-quick-search .autosuggest__results-item {
    padding: 15px 30px !important;
}
}
.mc-quick-search .autosuggest__results-item:active, .mc-quick-search .autosuggest__results-item:hover, .mc-quick-search .autosuggest__results-item:focus {
  background-color: #F2F2F2;
}
.mc-quick-search .autosuggest__results-item-icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  visibility: hidden;
}
.mc-quick-search .autosuggest__results-item-icon-item-icon--villa {
  background-color: rgba(10, 187, 135, 0.1);
}
.mc-quick-search .autosuggest__results-item-icon-item-icon--location {
  background-color: rgba(10, 187, 135, 0.1);
}
.mc-quick-search .autosuggest__results-before {
  padding: 15px 45px;
  text-align: left;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1.2380952381em;
  font-style: normal;
  font-weight: bold;
  color: #DD181F;
}
.mc-quick-search #autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}
.actions[data-v-6ddf3406] {
  margin: 30px auto;
  padding: 0 72px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}
.items-wrapper[data-v-6ddf3406] {
  margin: 0 auto;
  padding: 0 72px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}
.qs-item[data-v-6ddf3406] {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;
}
.qs-item__name[data-v-6ddf3406] {
  font-size: 1.2rem;
  font-weight: 600;
}
.qs-item__quantity input[type=number][data-v-6ddf3406]::-webkit-inner-spin-button,
.qs-item__quantity input[type=number][data-v-6ddf3406]::-webkit-outer-spin-button {
  -webkit-appearance: unset;
  margin: unset;
}
.qs-item__quantity input[type=number][data-v-6ddf3406] {
  -moz-appearance: unset;
}
.qs-item__quantity button[data-v-6ddf3406] {
  font-weight: 500;
  font-size: 0.9rem;
  background-color: #C7161C;
  color: #ffffff;
  padding: 2px 10px;
}
.qs-item__quantity-input[data-v-6ddf3406] {
  margin-right: 30px;
  border: 1px solid #f2f2f2;
  padding: 10px;
}
