.main-menu {

  @include media("<=1400px") {}

  @include media("<=1200px") {}

  @include media("<=992px") {
    padding: 24px 30px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999;
    transform: translateX(-100%);
    transition: transform 0.2s ease-in-out;
  }

  @include media("<=768px") {}

  @include media("<=576px") {}

  &__close {
    display: none;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 18px;
      width: 24px;
    }

    @include media("<=768px") {}

    @include media("<=576px") {}
  }

  &__menu-close-line {
    position: relative;
    display: block;
    width: 25px;
    height: 3px;
    background-color: $masterRed500;
    border-radius: 1px;
  }

  &__close-line--top {
    top: 4px;
    transform: rotate(45deg);
  }
  &__close-line--bottom {
    bottom: 5px;
    transform: rotate(-45deg);
  }

  &__items {
    padding: 0;
    display: flex;
    list-style: none;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {
      flex-direction: column;
    }

    @include media("<=768px") {}

    @include media("<=576px") {
      padding: 30px 0 0 0;
    }
  }
  &__item {
    position: relative;
    font-style: normal;
    font-weight: bold;
    @include fontSize(14);
    @include lineHeight(21, 14);
    text-transform: uppercase;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {
      padding: 15px 0;
      @include fontSize(21);
      @include lineHeight(26, 21);
      color: $masterBlack500;
      text-transform: capitalize;
      text-align: center;

      &:not(:last-child) {
        border-bottom: 1px solid #E6E6E6;
      }
    }

    @include media("<=768px") {}

    @include media("<=576px") {}

    &:not(:last-child) {
      margin-right: 40px;

      @include media("<=1400px") {}

      @include media("<=1200px") {}

      @include media("<=992px") {
        margin-right: 0;
      }

      @include media("<=768px") {}

      @include media("<=576px") {}
    }
  }
  &__link {
    color: $masterBlack200;
    text-decoration: none;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {
      color: $masterBlack500;
    }

    @include media("<=768px") {}

    @include media("<=576px") {}

    &:hover {
      color: $masterBlack500;
    }
  }

  &__link-red {
    color: $masterRed600;
    text-decoration: none;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {
      color: $masterRed600;
    }

    @include media("<=768px") {}

    @include media("<=576px") {}

    &:hover {
      color: $masterBlack500;
    }
  }

  &__link--active {
    color: $masterBlack500;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") {}

    @include media("<=768px") {
      color: $masterRed500;
    }

    @include media("<=576px") {}

    &::before {
      content: '';
      position: absolute;
      top: -41px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: $masterRed500;

      @include media("<=1400px") {}

      @include media("<=1200px") {}

      @include media("<=992px") {
        display: none;
      }

      @include media("<=768px") {}

      @include media("<=576px") {}
    }
  }

  &__mobile-cta {
    display: none;

    @include media("<=1400px") {}

    @include media("<=1200px") {}

    @include media("<=992px") { }

    @include media("<=768px") {}

    @include media("<=576px") {
      display: block;
    }
  }
}
